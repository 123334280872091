import React, { Fragment, useEffect, useState } from "react";
import PollutionMap from "../components/PollutionMap";
import { loadParkingData } from "../redux/actions";
import { connect } from "react-redux";
import { throttle } from "lodash";

// const MapHome = ({ app, dispatch }) => {
const MapHome = ({ dispatch }) => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const updateSize = () => {
    setSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    dispatch(loadParkingData("GRID"));
    const onResize = throttle(updateSize, 150, { trailing: true });
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [dispatch]);

  const { width, height } = size;

  return (
    <Fragment>
      <PollutionMap width={width} height={height} />
    </Fragment>
  );
};

// export default MapHome;

const mapStateToProps = (state) => state;
const dispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, dispatchToProps)(MapHome);
