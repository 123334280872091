import React from "react";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { StyledEngineProvider } from "@mui/material/styles";
import { RouterProvider } from "react-router-dom";
import routers from "./routes/Routes";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <RouterProvider router={routers} />
      </Provider>
    </StyledEngineProvider>
  );
}

export default App;
