import React from "react";
import { TeamCountData } from "../../assets/data/TeamCountData";

export const TeamCount = () => {
  return (
    <div className="-mt-10 container mx-auto pb-10 px-10">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {TeamCountData.map((item, index) => (
          <div key={index} className="relative">
            <div className="bg-white p-3 md:p-6 rounded-md shadow text-center">
              <div className="flex items-center justify-center text-3xl pb-4 text-primary">
                {item?.icon}
              </div>
              <div className="text-xl md:text-2xl font-bold mb-2 text-secondary">
                {item.count}
              </div>
              <div className="text-base-200 text-sm">{item.description}</div>
            </div>
            {index < TeamCountData.length - 1 && (
              <div className="absolute top-0 right-0 h-full bg-gray-300 w-px" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
