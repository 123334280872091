import React from "react";

const Contact = () => {
  return (
    <div className="">
      <h3 className="text-xl font-semibold text-red-800 text-center py-10">
        Page Coming Soon
      </h3>
    </div>
  );
};

export default Contact;
