import React from "react";
import { Link } from "react-router-dom";

const MobileNavItem = ({ item }) => {
  return (
    <div>
      <ScrollLink to={item?.link}>{item?.name}</ScrollLink>
    </div>
  );
};
export default MobileNavItem;

const ScrollLink = ({ to, children }) => {
  const handleClick = (e) => {
    e.preventDefault();
    const targetId = e.target.getAttribute("href").substring(1);
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <Link
      onClick={handleClick}
      to={to}
      className="font-medium text-base text-base-300 whitespace-nowrap hover:text-primary transition hover:duration-700"
    >
      {children}
    </Link>
  );
};
