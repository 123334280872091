import Processors from "kepler.gl/processors";
import { getMapConfig } from "../configs/map";
import { wrapTo, addDataToMap } from "kepler.gl/actions";

export const REQUEST_PARKING_DATA = "REQUEST_PARKING_DATA";
export const GET_PARKING_DATA = "GET_PARKING_DATA";
export const SET_MAP_MODE = "SET_MAP_MODE";

const demoDataUrl = "/data/pollutant.csv";
const info = {
  id: "parking_data",
  label: "Air Pollution Satellites",
};

export function loadParkingData(mapMode) {
  return (dispatch) => {
    dispatch(requestParkingData());

    fetch(demoDataUrl)
      .then((response) => response.text())
      .then((source) => {
        dispatch(getParkingData());

        const data = Processors.processCsvData(source);
        const config = getMapConfig(mapMode);
        const datasets = [{ info, data }];

        dispatch(
          wrapTo(
            "parking_map",
            addDataToMap({
              datasets,
              config,
            })
          )
        );
      });
  };
}

function getParkingData() {
  return {
    type: GET_PARKING_DATA,
  };
}

function requestParkingData() {
  return {
    type: REQUEST_PARKING_DATA,
  };
}
