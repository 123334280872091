import React from "react";
import { Link } from "react-router-dom";

const DemoSection = () => {
  return (
    <section id="demo" className="overflow-hidden px-5 md:px-0 py-14 md:py-28">
      <div className="container mx-auto">
        <div className="">
          <div className="flex items-center justify-center">
            <div className="text-center">
              <span className="mb-[.625rem] block text-base	 font-bold uppercase leading-[1.5] tracking-widest text-primary md:text-md">
                Check Our Demo
              </span>
              <h4 className="text-secondary text-xl  md:text-2xl font-bold leading-[1.25] mt-2.5">
                A Live Demo Example at Map
              </h4>
            </div>
          </div>

          <div className="mt-10 px-3 md:px-24 text-secondary flex-none md:flex md:justify-around">
            <div className="group shadow-dropShadowLg shadow-secondary/5 hover:shadow-primary/10 transition hover:duration-700 flex flex-col min-h-[64px] items-center justify-between gap-5 rounded-5  px-4 py-2 md:min-h-[85px] md:px-4 md:py-2">
              <span className="duration flex-none scale-100 transition-transform ease-linear group-hover:scale-90">
                <Link to="/maps">
                  <img
                    width="200"
                    height="200"
                    src="/images/demo/demo-1.png"
                    alt="data"
                  />
                </Link>
              </span>
              <Link to="/maps">
                <h3 className="text-center text-md font-bold leading-[1.25] md:text-lg">
                  Demo one
                </h3>
              </Link>
            </div>
            <div className="group shadow-dropShadowLg shadow-secondary/5 hover:shadow-primary/10 transition hover:duration-700 flex flex-col min-h-[64px] items-center justify-between gap-5 rounded-5  px-4 py-2 md:min-h-[85px] md:px-4 md:py-2">
              <span className="duration flex-none scale-100 transition-transform ease-linear group-hover:scale-90">
                <Link to="/maps">
                  <img
                    width="200"
                    height="200"
                    src="/images/demo/demo-2.png"
                    alt="data"
                  />
                </Link>
              </span>
              <Link to="/maps">
                <h3 className="text-md font-bold leading-[1.25] md:text-lg">
                  Demo two
                </h3>
              </Link>
            </div>
            <div className="group shadow-dropShadowLg shadow-secondary/5 hover:shadow-primary/10 transition hover:duration-700 flex flex-col min-h-[64px] items-center justify-between gap-5 rounded-5  px-4 py-2 md:min-h-[85px] md:px-4 md:py-2">
              <span className="duration flex-none scale-100 transition-transform ease-linear group-hover:scale-90">
                <Link to="/maps">
                  <img
                    width="200"
                    height="200"
                    src="/images/demo/demo-3.png"
                    alt="data"
                  />
                </Link>
              </span>
              <Link to="/maps">
                <h3 className="text-md font-bold leading-[1.25] md:text-lg">
                  Demo Three
                </h3>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default DemoSection;
