export const FooterSupportLinkData = {
  title: "Support",
  data: [
    {
      name: "Help Center",
      link: "#",
    },
    {
      name: "How We Work",
      link: "#",
    },
    {
      name: "Refund Policy",
      link: "#",
    },
    {
      name: "Terms & Condition",
      link: "#",
    },
    {
      name: "Privacy Policy",
      link: "#",
    },
  ],
};
