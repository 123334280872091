import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import NavItem from "./NavItem";
import { MainMenuData } from "../../../assets/data/mainMenu";
import { MobileNavModal } from "./MobileNavModal";

export const MainNav = () => {
  const [mobileMenu, setMobileMenu] = useState(false);

  const location = useLocation();
  const isMapPage = location.pathname === "/maps";

  return (
    <section>
      <nav className="bg-white border-t-2 border-neutral-content border-opacity-70 shadow-dropShadowLg">
        <div className="container mx-auto px-5 md:px-0">
          <div className="flex items-center justify-between gap-4">
            {/* site-logo  */}
            <Link to="/" className="py-2">
              <img
                src="/logo.png"
                alt="Logo"
                className={`max-w-lg w-full  h-auto ${
                  isMapPage ? "max-h-12" : "max-h-16"
                }`}
              />
            </Link>
            {/* menu-items  */}
            <div className="hidden lg:block">
              {isMapPage ? (
                <div className="flex justify-center items-center gap-6">
                  <Link
                    to={"/"}
                    className="font-medium bg-base-100 text-xs lg:text-sm uppercase text-base-300 whitespace-nowrap hover:text-primary transition hover:duration-700 py-3 px-3 rounded"
                  >
                    Back To Home
                  </Link>
                </div>
              ) : (
                <div className="flex justify-center items-center gap-6">
                  <Link
                    to={"/"}
                    className="font-medium text-xs lg:text-sm uppercase text-base-300 whitespace-nowrap hover:text-primary transition hover:duration-700 py-4"
                  >
                    Home
                  </Link>
                  {MainMenuData?.map((singleData, index) => (
                    <NavItem item={singleData} key={index} />
                  ))}
                </div>
              )}
            </div>
            {isMapPage ? (
              <div className="flex justify-center items-center gap-6  lg:hidden">
                <Link
                  to={"/"}
                  className="font-medium bg-base-100 text-xs lg:text-sm uppercase text-base-300 whitespace-nowrap hover:text-primary transition hover:duration-700 py-3 px-3 rounded"
                >
                  Back To Home
                </Link>
              </div>
            ) : (
              <GiHamburgerMenu
                className="text-2xl lg:hidden cursor-pointer"
                onClick={() => setMobileMenu(true)}
              />
            )}

            <div className="hidden lg:block">
              <div className="flex gap-1 items-center">
                {/* <Link
                  href={"/contact-us"}
                  className="font-normal text-xs sm:text-sm line-clamp-1 text-base-300 whitespace-nowrap hover:text-primary transition hover:duration-700 py-4"
                >
                  helpText
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </nav>
      <MobileNavModal mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />
    </section>
  );
};
