import React from "react";

const WorksSection = () => {
  return (
    <section
      id="our-works"
      className="overflow-hidden px-5 md:px-0 py-14 md:py-28"
    >
      <div className="container mx-auto">
        <div className="">
          <div className="flex items-center justify-center">
            <div className="text-center">
              <span className="mb-[.625rem] block text-base	 font-bold uppercase leading-[1.5] tracking-widest text-primary md:text-md">
                How It Works
              </span>
              <h2 className="text-secondary/40 text-3xl  md:text-5xl font-bold leading-[1.25] py-1">
                ECOSAT
              </h2>
              <h4 className="text-secondary text-xl  md:text-2xl font-bold leading-[1.25] mt-2.5">
                AI Tools Crafting A Pollution-Free Future
              </h4>
            </div>
          </div>
          <div className="text-center px5 md:px-20 mt-3 md:mt-8">
            <p className="mt-5 whitespace-pre-line">
              Transforming Pollution Monitoring through Advanced Satellite
              Imagery: Our innovative approach delivers in-depth insights into
              urban dynamics, empowering policymakers with the knowledge to
              formulate targeted strategies for tackling pollution at its
              source.
            </p>
          </div>
          <div className="mt-10 px-3 md:px-12 text-secondary flex-none md:flex md:justify-around">
            <div className="group shadow-dropShadowLg shadow-primary/5 hover:shadow-primary/10 transition hover:duration-700 flex flex-col min-h-[64px] items-center justify-between gap-5 rounded-5  px-6 py-2 md:min-h-[85px] md:px-[1.875rem] md:py-3">
              <span className="duration flex-none scale-100 transition-transform ease-linear group-hover:scale-90">
                <img
                  width="200"
                  height="200"
                  src="/images/work/satellite.png"
                  alt="data"
                />
              </span>
              <h3 className="text-md font-bold leading-[1.25] md:text-lg">
                SATALITE
              </h3>
            </div>
            <div className="flex items-center justify-center text-2xl font-bold py-7 md:py-0 md:px-10">
              +
            </div>
            <div className="group shadow-dropShadowLg shadow-primary/5 hover:shadow-primary/10 transition hover:duration-700 flex flex-col min-h-[64px] items-center justify-between gap-5 rounded-5  px-6 py-2 md:min-h-[85px] md:px-[1.875rem] md:py-3">
              <span className="duration flex-none scale-100 transition-transform ease-linear group-hover:scale-90">
                <img
                  width="200"
                  height="200"
                  src="/images/work/ai.png"
                  alt="data"
                />
              </span>
              <h3 className="text-md font-bold leading-[1.25] md:text-lg">
                AI
              </h3>
            </div>
            <div className="flex items-center justify-center text-2xl font-bold py-7 md:py-0 md:px-10">
              <span className="md:block hidden">=</span>
              <span className="block md:hidden">||</span>
            </div>
            <div className="group shadow-dropShadowLg shadow-primary/5 hover:shadow-primary/10 transition hover:duration-700 flex flex-col min-h-[64px] items-center justify-between gap-5 rounded-5  px-6 py-2 md:min-h-[85px] md:px-[1.875rem] md:py-3">
              <span className="duration flex-none scale-100 transition-transform ease-linear group-hover:scale-90">
                <img
                  width="400"
                  height="600"
                  src="/images/work/map.png"
                  alt="data"
                />
              </span>
              <h3 className="text-md font-bold leading-[1.25] md:text-lg">
                BETTER AIR QUALITY CONTROL
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WorksSection;
