import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { FooterQuickLinkData } from "../../assets/data/footer/FooterQuickLinkData";
import { FooterSupportLinkData } from "../../assets/data/footer/FooterSuppoerLinkData";
import { FooterSocialData } from "../../assets/data/footer/FooterSocialData";

const Footer = () => {
  return (
    <footer className="bg-black">
      <div id="#contact" className="container mx-auto px-5 md:px-10 lg:px-0">
        <div className="py-14 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-12 md:gap-14 lg:gap-0">
          {/* Info?Logo section */}
          <div className="flex md:flex-none flex-col items-center md:items-start">
            <div>
              <div className="flex items-center gap-2">
                {/* site-logo  */}
                <Link to="/" className="py-2">
                  <img
                    src="/logo.png"
                    alt="Logo"
                    className={`max-w-lg w-full  h-auto max-h-16 bg-white`}
                  />
                </Link>
              </div>
              {/* info  */}
              <Fragment>
                <p className="font-medium text-xs sm:text-sm text-white pt-6">
                  Address
                </p>
                <p className="font-normal text-xs sm:text-sm text-base-100">
                  UAE
                </p>
              </Fragment>
              <Fragment>
                <p className="font-medium text-xs sm:text-sm text-white pt-6">
                  Phone
                </p>
                <p className="font-normal text-xs sm:text-sm text-base-100">
                  +971527779008
                </p>
              </Fragment>
              <Fragment>
                <p className="font-medium text-xs sm:text-sm text-white pt-6">
                  Email
                </p>
                <p className="font-normal text-xs sm:text-sm text-base-100 mb-6">
                  Contact@EcoSat.ae
                </p>
              </Fragment>
            </div>
          </div>
          {/* Quick Link desktop */}
          <div className="hidden lg:block">
            <div className="mt-1.5">
              <p className="font-bold text-lg text-white">
                {FooterQuickLinkData.title}
              </p>
              <div className="flex flex-col gap-2.5 mt-4 w-full">
                {FooterQuickLinkData?.data?.map((singleData, index) => (
                  <div key={index}>
                    <Link to={singleData.link}>
                      <p className="font-normal text-xs sm:text-sm w-fit text-base-100 whitespace-nowrap hover:text-primary transition hover:duration-700">
                        {singleData.name}
                      </p>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Support Link desktop*/}
          <div className="hidden lg:block">
            <div className="mt-1.5">
              <p className="font-bold text-lg text-white">
                {FooterSupportLinkData.title}
              </p>
              <div className="flex flex-col gap-2.5 mt-4 w-full">
                {FooterSupportLinkData?.data?.map((singleData, index) => (
                  <div key={index}>
                    <Link to={singleData.link}>
                      <p className="font-normal text-xs sm:text-sm w-fit text-base-100 whitespace-nowrap hover:text-primary transition hover:duration-700">
                        {singleData.name}
                      </p>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Quick/Support Link mobile  */}
          <div className="flex gap-24 lg:hidden justify-center ">
            {/* Quick Link mobile  */}
            <div className="mt-1.5">
              <p className="font-bold text-lg text-white">
                {FooterQuickLinkData.title}
              </p>
              <div className="flex flex-col gap-2.5 mt-4 w-full">
                {FooterQuickLinkData?.data?.map((singleData, index) => (
                  <div key={index}>
                    <Link to={singleData.link}>
                      <p className="font-normal text-xs sm:text-sm w-fit text-base-100 whitespace-nowrap hover:text-primary transition hover:duration-700">
                        {singleData.name}
                      </p>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {/* Support Link */}
            <div className="mt-1.5">
              <p className="font-bold text-lg text-white">
                {FooterSupportLinkData.title}
              </p>
              <div className="flex flex-col gap-2.5 mt-4 w-full">
                {FooterSupportLinkData?.data?.map((singleData, index) => (
                  <div key={index}>
                    <Link to={singleData.link}>
                      <p className="font-normal text-xs sm:text-sm w-fit text-base-100 whitespace-nowrap hover:text-primary transition hover:duration-700">
                        {singleData.name}
                      </p>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className=" w-fit">
            {/* EmailSubscribe  */}
            <div>
              <p className="font-bold text-lg text-white mb-6">Newsletter</p>
              <p className="font-normal text-xs sm:text-sm text-base-100 mb-4">
                Subscribe to our newsletter
              </p>
              <form className="flex gap-0">
                <input
                  type="email"
                  name="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  required
                  className={`w-full xl:w-fit text-white text-sm leading-5 px-4 py-2.5 outline-none bg-secondary placeholder:text-base-200 rounded-l-full`}
                  placeholder={"Enter your email"}
                />
                <button
                  type="submit"
                  className={`text-base leading-6 px-6 py-2.5 bg-primary text-white hover:bg-secondary transition hover:duration-700 rounded-r-full`}
                >
                  <p className="font-semibold text-xs sm:text-sm py-[2px]">
                    Subscribe
                  </p>
                </button>
              </form>
            </div>
            {/* social-link  */}
            <div className="flex gap-2 mt-10">
              {FooterSocialData.map((singleData, index) => (
                <Link to={singleData?.link} key={index}>
                  <div className="p-3 w-fit bg-base-content rounded-full group">
                    <p className=" text-white group-hover:text-primary transition hover:duration-700 text-base">
                      {singleData?.icon}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <hr className=" border-secondary w-full" />
        <div className="py-6 flex items-center justify-center gap-6">
          <p className="font-normal text-base text-base-100">
            © 2024 ECPSAT. All Rights Reserve.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
