// ================
import { createStore, applyMiddleware, compose } from "redux";
import { taskMiddleware } from "react-palm/tasks";
import { thunk } from "redux-thunk";

import reducers from "./reducers";

// using enhancers
const initialState = {};
const middlewares = [taskMiddleware, thunk];
const enhancers = [applyMiddleware(...middlewares)];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  reducers,
  initialState,
  composeEnhancers(...enhancers)
);
// ============
// import { createStore, applyMiddleware } from "redux";
// import { thunk } from "redux-thunk";
// import reducers from "./reducers";

// export const store = createStore(reducers, {}, applyMiddleware(thunk));
// ===================

// import { createStore, applyMiddleware } from "redux";
// import { taskMiddleware } from "react-palm/tasks";
// import { thunk } from "redux-thunk";
// import thunkMiddleware from "redux-thunk";
// import reducers from "./reducers";
// using enhancers
// const initialState = {};
// const middlewares = [taskMiddleware, thunkMiddleware];
// const enhancers = [applyMiddleware(...middlewares)];

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// export default createStore(
//   reducers,
//   initialState,
//   composeEnhancers(...enhancers)
// );

// import keplerGlReducer from "kepler.gl/reducers";

// const keplerReducer = keplerGlReducer.initialState({
//   uiState: {
//     //activeSidePanel: null, // hide side panel when first init/open app
//     currentModal: null, // dont show import data modal when first init/open app
//   },
// });

// const reducer = combineReducers({
//   keplerGl: keplerReducer,
// });

// create store
// export const store = createStore(reducers, {}, applyMiddleware(taskMiddleware));

// export const store = createStore(reducers, {}, applyMiddleware(thunk));
