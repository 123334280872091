export const TeamData = [
  {
    name: "Ahmed Alshamsi",
    title: "CHIEF EXECUTIVE OFFICER",
    image: "/images/teams/team-1.png",
    social: {
      facebook: "https://www.facebook.com/",
      linkedin: "https://www.linkedin.com/",
    },
  },
  {
    name: "Latifa Alnuaim",
    title: "CHIEF OPERATING OFFICER",
    image: "/images/teams/team-2.png",
    social: {
      facebook: "https://www.facebook.com/",
      linkedin: "https://www.linkedin.com/",
    },
  },
  {
    name: "Bashayer Almarshoodi",
    title: "CHIEF FINANCIAL OFFICER",
    image: "/images/teams/team-3.png",
    social: {
      facebook: "https://www.facebook.com/",
      linkedin: "https://www.linkedin.com/",
    },
  },
  {
    name: "Tahani Alazazi",
    title: "CHIEF TECHNOLOGY OFFICER",
    image: "/images/teams/team-4.png",
    social: {
      facebook: "https://www.facebook.com/",
      linkedin: "https://www.linkedin.com/",
    },
  },
  // Add other team members as needed
];
