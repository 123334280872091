import React from "react";
import { Link } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { MainMenuData } from "../../../assets/data/mainMenu";
import MobileNavItem from "./MobileNavItem";

export const MobileNavModal = ({ mobileMenu, setMobileMenu }) => {
  return (
    <nav>
      <div className="block lg:hidden">
        <div
          className={`overflow-y-auto z-40 flex pt-5 top-0 flex-col h-screen w-full max-w-[300px] fixed bg-white  duration-500  ease-in-out  gap-2 md:gap-0 ${
            mobileMenu ? "left-0" : "-left-full"
          }`}
        >
          <div className="relative flex flex-col gap-5">
            <div className="flex items-center justify-between px-6">
              <p className="font-semibold text-lg">Menu</p>
              <RxCross2
                className=" text-base-200 font-bold text-xl cursor-pointer transition hover:text-primary  hover:duration-700"
                onClick={() => setMobileMenu(!mobileMenu)}
              />
            </div>
          </div>
          <div className=" mt-4 border border-secondary-content"></div>

          {/* menu-items  */}
          <div className=" mt-7 px-6 flex flex-col gap-5">
            <Link
              to={"/"}
              className="font-medium text-base text-base-300 whitespace-nowrap hover:text-primary transition hover:duration-700"
            >
              Home
            </Link>
            {MainMenuData?.map((singleData, index) => (
              <div key={index} onClick={() => setMobileMenu(false)}>
                <MobileNavItem item={singleData} />
                {/* <Link
                  href={singleData?.link}
                  className="font-medium text-base text-base-300 whitespace-nowrap hover:text-primary transition hover:duration-700"
                >
                  {singleData?.name}
                </Link> */}
              </div>
            ))}
          </div>

          <div className="mt-7 border border-secondary-content"></div>
        </div>
      </div>
      <div
        className={`lg:hidden fixed top-0 z-30 transition-all duration-500 ease-in-out  bg-[#868687] opacity-80 h-full w-full ${
          mobileMenu ? "left-0" : "-left-full"
        }`}
        onClick={() => setMobileMenu(false)}
      />
    </nav>
  );
};
