import React from "react";

const BannerSection = () => {
  return (
    <section
      style={{
        backgroundImage: `url(/images/banner/banner-bg-main.png)`,
      }}
      className={`relative`}
    >
      <div className="bg-primary/20">
        <div className=" absolute top-0 z-20 transition-all duration-500 ease-in-out  bg-[#18183a] opacity-80 h-full w-full left-0"></div>
        <div className="z-30 container mx-auto flex items-center justify-start px-5 md:px-0 h-[248px] md:h-[440px] lg:h-[760px] relative">
          <div className=" w-1/2 z-10">
            <div>
              <p className="hidden md:block font-semibold text-base md:text-lg lg:text-xl text-primary mb-2">
                Advanced Solutions For Your Data
              </p>
              <h6 className="text-xl md:text-5xl lg:text-7xl font-light md:leading-[60px] xl:leading-[84px] text-white">
                The Ultimate Innovative Solutions
              </h6>
              <h2 className="text-xl md:text-5xl lg:text-7xl md:leading-[60px] xl:leading-[88px] font-black text-white">
                Geospatial AI
              </h2>
            </div>
          </div>
          <div className="hidden md:block">
            <img
              src={"/images/banner/banner-1.png"}
              alt={"Image"}
              width={520}
              height={520}
              className="rounded"
            />
          </div>
          <div className="block md:hidden">
            <img
              src={"/images/banner/banner-1.png"}
              alt={"Image"}
              width={170}
              height={174}
              className="rounded"
            />
          </div>
          <div className="absolute bottom-[60px] right-0 hidden md:block">
            <img
              src={"/images/banner/banner-2.png"}
              alt={"Image"}
              width={180}
              height={180}
              className="rounded-xl"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
