export const FooterQuickLinkData = {
  title: "Quick Link",
  data: [
    {
      name: "About",
      link: "#",
    },
    {
      name: "Services",
      link: "#",
    },
    {
      name: "Special Offer",
      link: "#",
    },
    {
      name: "New Service",
      link: "#",
    },
    {
      name: "Contact Us",
      link: "#",
    },
  ],
};
