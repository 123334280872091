import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaLinkedin } from "react-icons/fa";
import { TeamData } from "../../assets/data/TeamData";
import { TeamCount } from "./TeamCount";

const TeamSection = () => {
  return (
    <>
      <section
        id="our-team"
        className="overflow-hidden bg-base-100/30 py-14 md:py-28"
      >
        <div className="container mx-auto my-10 bg-secondary p-5 rounded-md">
          {/* heading-area  */}
          <div className="mb-10 mr-[30px] w-full md:max-w-[680px] md:mb-[3.75rem] md:pr-[140px]">
            <div className="text-left">
              <span className="mb-2.5 text-primary text-base md:text-md block font-bold uppercase tracking-widest ">
                Our Team
              </span>
              <h2 className="text-white/70 font-bold text-xl md:text-2xl">
                Transforming Challenges into Seamless Solutions
              </h2>
            </div>
          </div>
          {/* team members  */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
            {TeamData.map((member, index) => (
              <div
                key={index}
                className="bg-white/5 p-10 rounded-lg shadow-md hover:shadow-lg transition hover:duration-700"
              >
                <img
                  src={member?.image}
                  alt={member?.name}
                  width={150}
                  height={150}
                  className="w-full h-auto mb-4 rounded-full"
                />
                <h2 className="text-primary-content capitalize text-xl font-semibold mb-2">
                  {member?.name}
                </h2>
                <p className="text-base-200 mb-2">{member?.title}</p>
                <div className="flex space-x-4">
                  {member.social.facebook && (
                    <Link
                      to={member.social.facebook}
                      target="_blank"
                      className="text-primary/50 hover:text-primary/80 transition hover:duration-700"
                    >
                      <FaFacebook className="h-5 w-5" />
                    </Link>
                  )}
                  {member.social.linkedin && (
                    <Link
                      to={member.social.linkedin}
                      target="_blank"
                      className="text-primary/50 hover:text-primary/80 transition hover:duration-700"
                    >
                      <FaLinkedin className="h-5 w-5" />
                    </Link>
                  )}
                  {/* Add other social links as needed */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <TeamCount />
    </>
  );
};

export default TeamSection;
