import React from "react";
import { BsDatabaseCheck } from "react-icons/bs";
import { TbChartDots2 } from "react-icons/tb";
import { TbBrain } from "react-icons/tb";

const AboutSection = () => {
  return (
    <section
      id="about-us"
      className="overflow-hidden px-5 md:px-0 py-14 md:py-28"
    >
      <div className="container mx-auto">
        <div className="grid items-center gap-12 lg:grid-cols-[.92fr_1fr] 2xl:gap-20">
          <div className="lg:order-2">
            <div className="text-left">
              <span className="mb-[.625rem] block text-base	 font-bold uppercase leading-[1.5] tracking-widest text-primary md:text-md">
                About us
              </span>
              <h2 className="text-secondary text-xl  md:text-2xl font-bold leading-[1.25]">
                Empowering Innovative Geospatial AI solutions
              </h2>
              <p className="mt-5 whitespace-pre-line">
                ECOASAT is a startup Emirati company with only Emiratis
                shareholders. Ecosat Focuses on AI-powered geospatial
                application that serves different sectors like environment,
                climate and community development
              </p>
              <p className="mt-5 md:mt-[1.875rem]">
                Emerge as the foremost company in the region, specializing in
                exemplary data acquisition, cutting-edge data processing, and
                innovative AI solutions
              </p>
            </div>
            <div className="mt-7 px-3 md:px-12 grid gap-5 text-secondary sm:grid-cols-1 md:mt-10 md:gap-[1.875rem]">
              <div className="group shadow-dropShadowLg shadow-primary/10 hover:shadow-primary/20 transition hover:duration-700 flex min-h-[64px] items-center justify-between gap-5 rounded-5  px-6 py-2 md:min-h-[85px] md:px-[1.875rem] md:py-3">
                <span className="duration flex-none scale-100 transition-transform ease-linear group-hover:scale-90">
                  <BsDatabaseCheck className="w-12 h-12 text-primary" />
                </span>
                <h3 className="text-md font-bold leading-[1.25] md:text-lg">
                  Data acquisition
                </h3>
              </div>
              <div className="group shadow-dropShadowLg shadow-primary/10 hover:shadow-primary/20 transition hover:duration-700 flex min-h-[64px] items-center justify-between gap-5 rounded-5  px-6 py-2 md:min-h-[85px] md:px-[1.875rem] md:py-3">
                <span className="duration flex-none scale-100 transition-transform ease-linear group-hover:scale-90">
                  <TbChartDots2 className="w-12 h-12 text-primary" />
                </span>
                <h3 className="text-md font-bold leading-[1.25] md:text-lg">
                  Data proccesing
                </h3>
              </div>
              <div className="group shadow-dropShadowLg shadow-primary/10 hover:shadow-primary/20 transition hover:duration-700 flex min-h-[64px] items-center justify-between gap-5 rounded-5  px-6 py-2 md:min-h-[85px] md:px-[1.875rem] md:py-3">
                <span className="duration flex-none scale-100 transition-transform ease-linear group-hover:scale-90">
                  <TbBrain className="w-12 h-12 text-primary" />
                </span>
                <h3 className="text-md font-bold leading-[1.25] md:text-lg">
                  AI Solutions
                </h3>
              </div>
            </div>

            <p className="mt-5 md:mt-[1.875rem]">
              Our mission is to deliver indispensable data infrastructure and
              advanced AI solutions, empowering decision-makers with critical
              insights for informed and strategic choices
            </p>
          </div>
          {/* image-section  */}
          <div className="mx-auto text-center">
            <div className="relative mx-auto grid max-w-[580px] grid-cols-2 gap-2.5 overflow-hidden">
              <div className="relative flex justify-end overflow-hidden">
                <div
                  className="relative z-[2] mt-auto aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <span className="absolute -left-2.5 -top-2.5 -z-1 h-full w-full rounded-tl-[60px] bg-primary-light"></span>
                  <div className="relative z-[4] overflow-hidden">
                    <img
                      alt="about one"
                      loading="lazy"
                      width="195"
                      height="132"
                      decoding="async"
                      data-nimg="1"
                      className="rounded-tl-[60px] object-cover object-top"
                      style={{ color: "transparent" }}
                      src="/images/about/about-1.png"
                    />
                  </div>
                </div>
                <img
                  alt="pattern-1"
                  data-aos="zoom-in"
                  data-aos-delay="250"
                  loading="lazy"
                  width="383"
                  height="246"
                  decoding="async"
                  data-nimg="1"
                  className="absolute bottom-[-55%] left-[42px] z-1 block object-cover aos-init aos-animate"
                  style={{ color: "transparent" }}
                  src="/images/about/about-1-2.png"
                />
              </div>
              <div className="flex overflow-hidden">
                <img
                  alt="about two"
                  data-aos="fade-right"
                  data-aos-delay="300"
                  loading="lazy"
                  width="312"
                  height="304"
                  decoding="async"
                  data-nimg="1"
                  className="rounded-[80px_10px] object-cover aos-init aos-animate"
                  style={{ color: "transparent" }}
                  src="/images/about/about-2.png"
                />
              </div>
              <div className="ml-auto flex max-w-[250px] justify-end overflow-hidden">
                <img
                  alt="about four"
                  data-aos="fade-left"
                  data-aos-delay="350"
                  loading="lazy"
                  width="250"
                  height="265"
                  decoding="async"
                  data-nimg="1"
                  className="rounded-[50px_10px] object-cover aos-init aos-animate"
                  style={{ color: "transparent" }}
                  src="/images/about/about-3.png"
                />
              </div>
              <div className="overflow-hidden">
                <img
                  alt="about four"
                  data-aos="fade-right"
                  data-aos-delay="400"
                  loading="lazy"
                  width="250"
                  height="265"
                  decoding="async"
                  data-nimg="1"
                  className="rounded-10 rounded-br-[80px] object-cover aos-init aos-animate"
                  style={{ color: "transparent" }}
                  src="/images/about/about-4.png"
                />
              </div>
              <div className="absolute left-1/2 top-1/2 z-[4] -translate-x-1/2 -translate-y-1/2 overflow-hidden">
                <div
                  data-aos="zoom-in"
                  data-aos-delay="450"
                  className="aos-init aos-animate"
                >
                  <span className="grid h-[100px] w-[100px]  place-items-center rounded-full border-[12px] border-white bg-primary text-[30px] text-white">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 27 27"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M26.8125 13.8672C26.8125 14.9922 25.875 15.9297 24.8125 15.9297H15.8125V24.9297C15.8125 25.9922 14.875 26.8672 13.8125 26.8672C12.6875 26.8672 11.8125 25.9922 11.8125 24.9297V15.9297H2.8125C1.6875 15.9297 0.8125 14.9922 0.8125 13.8672C0.8125 12.8047 1.6875 11.9297 2.8125 11.9297H11.8125V2.92969C11.8125 1.80469 12.6875 0.867188 13.8125 0.867188C14.875 0.867188 15.8125 1.80469 15.8125 2.92969V11.9297H24.8125C25.875 11.8672 26.8125 12.8047 26.8125 13.8672Z"></path>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
