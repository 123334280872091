import React from "react";
import { FaPeopleArrows } from "react-icons/fa";
import { GiArchiveResearch } from "react-icons/gi";
import { FaAward } from "react-icons/fa6";
import { GiDiamondTrophy } from "react-icons/gi";

export const TeamCountData = [
  {
    count: "7+Researches",
    description: " Research Experience",
    icon: <GiArchiveResearch />,
  },
  {
    count: "5+ famous",
    description: "Influencers Endorsement",
    icon: <FaPeopleArrows />,
  },
  {
    count: "7+ Awards",
    description: " Total Awards",
    icon: <GiDiamondTrophy />,
  },
  {
    count: "4 IT-degrees",
    description: "3 BACHELOR, 1 MASTER",
    icon: <FaAward />,
  },
];
