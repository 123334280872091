import React from "react";
import AboutSection from "../components/HomePages/AboutSection";
import WorksSection from "../components/HomePages/WorksSection";
import TeamSection from "../components/HomePages/TeamSection";
import BannerSection from "../components/HomePages/BannerSection";
import DemoSection from "../components/HomePages/DemoSection";
import Footer from "./Shared/Footer";

const Home = () => {
  return (
    <main>
      <BannerSection />
      <AboutSection />
      <WorksSection />
      <TeamSection />
      <DemoSection />
      <Footer />
    </main>
  );
};

export default Home;
