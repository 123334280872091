export const MainMenuData = [
  // {
  //   name: "Home",
  //   link: "/",
  // },
  {
    name: "About Us",
    link: "about-us",
  },
  {
    name: "Our Works",
    link: "our-works",
  },
  {
    name: "Our Team",
    link: "our-team",
  },
  {
    name: "Demo",
    link: "demo",
  },
];
export const MapPageMenuData = [
  // {
  //   name: "Home",
  //   link: "/",
  // },
  {
    name: "About Us",
    link: "about-us",
  },
  {
    name: "Our Works",
    link: "our-works",
  },
  {
    name: "Our Team",
    link: "our-team",
  },
  {
    name: "Demo",
    link: "demo",
  },
];
