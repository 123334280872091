import React from "react";
import KeplerGl from "./KeplerGl";

const mapboxAccessToken = process.env.REACT_APP_MAPBOX_KEY;

const PollutionMap = ({ width, height }) => {
  return (
    <KeplerGl
      id="parking_map"
      appName="EcoSat"
      logoComponent={false}
      mapboxApiAccessToken={mapboxAccessToken}
      //   width={window.innerWidth}
      //   height={window.innerHeight}
      width={width}
      height={height}
      mint={false}
    />
  );
};

export default PollutionMap;
